import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LoginInfo } from 'projects/pw-lib/src/public-api';
import { filter } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { AuthService } from '../../auth/auth.service';
import { UiService } from '../../core/services/ui.service';
import { MenuService } from '../../services/menu.service';
import { DialogService } from '../dialog/dialog.service';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-kumahanin-header',
  templateUrl: './kumahanin-header.component.html',
  styleUrls: ['./kumahanin-header.component.scss'],
})
export class KumahaninHeaderComponent implements OnInit {
  showMenu = false;

  get showBack(): boolean {
    return !this.router?.url?.startsWith('/home');
  }

  loginInfo: LoginInfo;

  constructor(
    public menu: MenuService,
    public uiService: UiService,
    private router: Router,
    private languageService: LanguageService,
    private dialogService: DialogService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.loginSubject$.subscribe((info) => {
      this.loginInfo = info;
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.showMenu = false;
      });
  }

  onMouseEnter(): void {
    this.showMenu = true;
  }

  onMouseLeave(): void {
    this.showMenu = false;
  }

  onBackClick(): void {
    window.history.back();
  }

  onToggleLangClick(): void {
    if (this.languageService.lang === 'ko') {
      this.languageService.changeLang('ja');
    } else {
      this.languageService.changeLang('ko');
    }
  }

  onLoginClick(): void {
    this.dialogService.matDialog.open(LoginComponent, {
      disableClose: true,
    });
  }

  onRegisterClick(): void {
    this.dialogService.matDialog.open(RegisterComponent, {
      disableClose: true,
      width: '100%',
    });
  }

  onLogoutClick(): void {
    this.loginInfo = null;
    this.authService.logout();
  }
}
