import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { PageRepositoryService } from './shared/abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService extends PageRepositoryService<any> {
  protected baseUri = 'api/userInfo';

  constructor(protected http: HttpClient, protected authService: AuthService) {
    super(http);
  }

  updatePw(id: any, item: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http
      .put<any>(`${this.apiServerUrl}/${this.baseUri}/updatePw/${id}`, item)
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }
}
