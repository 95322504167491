import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { KumahaninLayoutComponent } from './components/kumahanin-layout/kumahanin-layout.component';
import { AppUpdateGuard } from './core/app-update.guard';
import { CertGuard } from './guards/cert.guard';

const routes: Routes = [
  {
    path: '',
    component: KumahaninLayoutComponent,
    canActivate: [AppUpdateGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      { path: 'intro', redirectTo: 'intro/home', pathMatch: 'full' },
      {
        path: 'intro/home',
        loadChildren: () =>
          import('./components/intro-home/intro-home.module').then(
            (m) => m.IntroHomeModule
          ),
      },
      {
        path: 'intro/mindan',
        loadChildren: () =>
          import('./components/intro-mindan/intro-mindan.module').then(
            (m) => m.IntroMindanModule
          ),
      },
      // TODO: 추후 추가
      // {
      //   path: 'intro/hanin',
      //   loadChildren: () =>
      //     import('./components/intro-hanin/intro-hanin.module').then(
      //       (m) => m.IntroHaninModule
      //     ),
      // },
      // {
      //   path: 'intro/kyopo',
      //   loadChildren: () =>
      //     import('./components/intro-kyopo/intro-kyopo.module').then(
      //       (m) => m.IntroKyopoModule
      //     ),
      // },
      { path: 'event', redirectTo: 'event/intro', pathMatch: 'full' },
      {
        path: 'event/intro',
        data: { type: 'EVENT' },
        loadChildren: () =>
          import('./components/bbs-photo-list/bbs-photo-list.module').then(
            (m) => m.BbsPhotoListModule
          ),
      },
      {
        path: 'event/intro/:id',
        data: { type: 'EVENT' },
        loadChildren: () =>
          import('./components/bbs-detail/bbs-detail.module').then(
            (m) => m.BbsDetailModule
          ),
      },
      {
        path: 'event/annual',
        loadChildren: () =>
          import('./components/event-annual/event-annual.module').then(
            (m) => m.EventAnnualModule
          ),
      },
      {
        path: 'event/annual/:id',
        loadChildren: () =>
          import('./components/edu-detail/edu-detail.module').then(
            (m) => m.EduDetailModule
          ),
      },
      { path: 'history', redirectTo: 'history/myeongseong', pathMatch: 'full' },
      // TODO: 추후 추가
      // {
      //   path: 'history/home',
      //   loadChildren: () =>
      //     import('./components/history-home/history-home.module').then(
      //       (m) => m.HistoryHomeModule
      //     ),
      // },
      {
        path: 'history/myeongseong',
        loadChildren: () =>
          import(
            './components/history-myeongseong/history-myeongseong.module'
          ).then((m) => m.HistoryMyeongseongModule),
      },
      { path: 'support', redirectTo: 'support/company', pathMatch: 'full' },
      // TODO: 추후 추가
      // {
      //   path: 'support/tour',
      //   loadChildren: () =>
      //     import('./components/support-tour/support-tour.module').then(
      //       (m) => m.SupportTourModule
      //     ),
      // },
      {
        path: 'support/company',
        data: { type: 'COMPANY' },
        loadChildren: () =>
          import('./components/bbs-photo-list/bbs-photo-list.module').then(
            (m) => m.BbsPhotoListModule
          ),
      },
      {
        path: 'support/company/:id',
        data: { type: 'COMPANY' },
        loadChildren: () =>
          import('./components/bbs-detail/bbs-detail.module').then(
            (m) => m.BbsDetailModule
          ),
      },
      {
        path: 'shop',
        loadChildren: () =>
          import('./components/shop/shop.module').then((m) => m.ShopModule),
      },
      { path: 'edu', redirectTo: 'edu/korean', pathMatch: 'full' },
      {
        path: 'edu/korean',
        data: { type: 'EDU_KOREAN' },
        loadChildren: () =>
          import('./components/edu-list/edu-list.module').then(
            (m) => m.EduListModule
          ),
      },
      {
        path: 'edu/children',
        data: { type: 'EDU_CHILDREN' },
        loadChildren: () =>
          import('./components/edu-list/edu-list.module').then(
            (m) => m.EduListModule
          ),
      },
      {
        path: 'edu/kfood',
        data: { type: 'EDU_KFOOD' },
        loadChildren: () =>
          import('./components/edu-list/edu-list.module').then(
            (m) => m.EduListModule
          ),
      },
      {
        path: 'edu/kculture',
        data: { type: 'EDU_KCULTURE' },
        loadChildren: () =>
          import('./components/edu-list/edu-list.module').then(
            (m) => m.EduListModule
          ),
      },
      { path: 'news', redirectTo: 'news/notice', pathMatch: 'full' },
      {
        path: 'news/notice',
        data: { type: 'NOTICE' },
        loadChildren: () =>
          import('./components/bbs-list/bbs-list.module').then(
            (m) => m.BbsListModule
          ),
      },
      {
        path: 'news/notice/:id',
        data: { type: 'NOTICE' },
        loadChildren: () =>
          import('./components/bbs-detail/bbs-detail.module').then(
            (m) => m.BbsDetailModule
          ),
      },
      {
        path: 'news/kumamoto',
        data: { type: 'KUMAMOTO' },
        loadChildren: () =>
          import('./components/bbs-list/bbs-list.module').then(
            (m) => m.BbsListModule
          ),
      },
      {
        path: 'news/kumamoto/:id',
        data: { type: 'KUMAMOTO' },
        loadChildren: () =>
          import('./components/bbs-detail/bbs-detail.module').then(
            (m) => m.BbsDetailModule
          ),
      },
      {
        path: 'news/photo',
        data: { type: 'PHOTO' },
        loadChildren: () =>
          import('./components/bbs-photo-list/bbs-photo-list.module').then(
            (m) => m.BbsPhotoListModule
          ),
      },
      {
        path: 'news/photo/:id',
        data: { type: 'PHOTO' },
        loadChildren: () =>
          import('./components/bbs-detail/bbs-detail.module').then(
            (m) => m.BbsDetailModule
          ),
      },
      { path: 'my', redirectTo: 'my/account', pathMatch: 'full' },
      {
        path: 'my/account',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/my-account/my-account.module').then(
            (m) => m.MyAccountModule
          ),
      },
      {
        path: 'my/account/form',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/my-account-form/my-account-form.module').then(
            (m) => m.MyAccountFormModule
          ),
      },
      {
        path: 'my/cart',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/shop/shop-cart/cart.module').then(
            (m) => m.CartModule
          ),
      },
      {
        path: 'my/ordr-hist',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/my-ordr-hist/my-ordr-hist.module').then(
            (m) => m.MyOrdrHistModule
          ),
      },
      {
        path: 'my/ordr-hist/:id',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './components/my-ordr-hist-detail/my-ordr-hist-detail.module'
          ).then((m) => m.MyOrdrHistDetailModule),
      },
      {
        path: 'my/rsrv-hist',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './components/my-rsrv-hist-list/my-rsrv-hist-list.module'
          ).then((m) => m.MyRsrvHistListModule),
      },
      {
        path: 'my/rsrv-hist/:id',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './components/my-rsrv-hist-detail/my-rsrv-hist-detail.module'
          ).then((m) => m.MyRsrvHistDetailModule),
      },
      {
        path: 'find-pw/:cert',
        canActivate: [CertGuard],
        loadChildren: () =>
          import('./components/find-pw/find-pw.module').then(
            (m) => m.FindPwModule
          ),
      },

      // {
      //   path: 'intro/greet',
      //   loadChildren: () =>
      //     import('./components/intro-greet/intro-greet.module').then(
      //       (m) => m.IntroGreetModule
      //     ),
      // },
      // {
      //   path: 'intro/guide',
      //   loadChildren: () =>
      //     import('./components/intro-guide/intro-guide.module').then(
      //       (m) => m.IntroGuideModule
      //     ),
      // },
      // {
      //   path: 'intro/history',
      //   loadChildren: () =>
      //     import('./components/intro-history/intro-history.module').then(
      //       (m) => m.IntroHistoryModule
      //     ),
      // },
      // {
      //   path: 'intro/map',
      //   loadChildren: () =>
      //     import('./components/intro-map/intro-map.module').then(
      //       (m) => m.IntroMapModule
      //     ),
      // },
      // { path: 'intro', redirectTo: 'intro/greet', pathMatch: 'full' },
      // {
      //   path: 'org/chart',
      //   loadChildren: () =>
      //     import('./components/org-chart/org-chart.module').then(
      //       (m) => m.OrgChartModule
      //     ),
      // },
      // {
      //   path: 'org/member',
      //   loadChildren: () =>
      //     import('./components/org-member/org-member.module').then(
      //       (m) => m.OrgMemberModule
      //     ),
      // },
      // { path: 'org', redirectTo: 'org/chart', pathMatch: 'full' },
      // {
      //   path: 'biz/trade-school',
      //   loadChildren: () =>
      //     import('./components/biz-trade-school/biz-trade-school.module').then(
      //       (m) => m.BizTradeSchoolModule
      //     ),
      // },
      // {
      //   path: 'biz/friend',
      //   loadChildren: () =>
      //     import('./components/biz-friend/biz-friend.module').then(
      //       (m) => m.BizFriendModule
      //     ),
      // },
      // {
      //   path: 'biz/localize',
      //   loadChildren: () =>
      //     import('./components/biz-localize/biz-localize.module').then(
      //       (m) => m.BizLocalizeModule
      //     ),
      // },
      // {
      //   path: 'biz/recruit',
      //   loadChildren: () =>
      //     import('./components/biz-recruit/biz-recruit.module').then(
      //       (m) => m.BizRecruitModule
      //     ),
      // },
      // { path: 'biz', redirectTo: 'biz/trade-school', pathMatch: 'full' },
      // {
      //   path: 'gbc/service',
      //   loadChildren: () =>
      //     import('./components/gbc-service/gbc-service.module').then(
      //       (m) => m.GbcServiceModule
      //     ),
      // },
      // {
      //   path: 'gbc/benefit',
      //   loadChildren: () =>
      //     import('./components/gbc-benefit/gbc-benefit.module').then(
      //       (m) => m.GbcBenefitModule
      //     ),
      // },
      // { path: 'gbc', redirectTo: 'gbc/service', pathMatch: 'full' },
      // {
      //   path: 'gallery/photo',
      //   loadChildren: () =>
      //     import('./components/gallery-photo/gallery-photo.module').then(
      //       (m) => m.GalleryPhotoModule
      //     ),
      // },
      // {
      //   path: 'gallery/photo/:id',
      //   loadChildren: () =>
      //     import(
      //       './components/gallery-photo-detail/gallery-photo-detail.module'
      //     ).then((m) => m.GalleryPhotoDetailModule),
      // },
      // {
      //   path: 'gallery/video',
      //   loadChildren: () =>
      //     import('./components/gallery-video/gallery-video.module').then(
      //       (m) => m.GalleryVideoModule
      //     ),
      // },
      // {
      //   path: 'gallery/video/:id',
      //   loadChildren: () =>
      //     import(
      //       './components/gallery-video-detail/gallery-video-detail.module'
      //     ).then((m) => m.GalleryVideoDetailModule),
      // },
      // { path: 'gallery', redirectTo: 'gallery/photo', pathMatch: 'full' },
      // {
      //   path: 'member-company',
      //   loadChildren: () =>
      //     import('./components/member-company/member-company.module').then(
      //       (m) => m.MemberCompanyModule
      //     ),
      // },
      // {
      //   path: 'community/notice',
      //   loadChildren: () =>
      //     import('./components/community-notice/community-notice.module').then(
      //       (m) => m.CommunityNoticeModule
      //     ),
      // },
      // {
      //   path: 'community/notice/:id',
      //   loadChildren: () =>
      //     import(
      //       './components/community-notice-detail/community-notice-detail.module'
      //     ).then((m) => m.CommunityNoticeDetailModule),
      // },
      // {
      //   path: 'community/news',
      //   loadChildren: () =>
      //     import('./components/community-news/community-news.module').then(
      //       (m) => m.CommunityNewsModule
      //     ),
      // },
      // {
      //   path: 'community/news/:id',
      //   loadChildren: () =>
      //     import(
      //       './components/community-news-detail/community-news-detail.module'
      //     ).then((m) => m.CommunityNewsDetailModule),
      // },
      // { path: 'community', redirectTo: 'community/notice', pathMatch: 'full' },
      { path: '**', redirectTo: 'home' },
    ],
  },
  // // 뒤로가기
  // { path: 'back', canActivate: [RouteGuard], component: MainLayoutComponent },
  // // 상태 유지하며 홈으로 이동
  // { path: 'root', canActivate: [RouteGuard], component: MainLayoutComponent },
  // // 상태 초기화하며 홈으로 이동
  // {
  //   path: 'root/reset',
  //   canActivate: [RouteGuard],
  //   component: MainLayoutComponent,
  // },
  // // 로그아웃
  // { path: 'logout', canActivate: [RouteGuard], component: MainLayoutComponent },
  // // 로그아웃, 메시지 표시
  // {
  //   path: 'logout/:msg',
  //   canActivate: [RouteGuard],
  //   component: MainLayoutComponent,
  // },
  // // 페이지 상태 초기화
  // { path: 'reset', component: ResetComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
