/* eslint-disable max-classes-per-file */
import { registerLocaleData, ViewportScroller } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeKo from '@angular/common/locales/ko';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import {
  MatDialogConfig,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Event, Router, Scroll } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor, PW_STORAGE_PREFIX } from 'pw-lib';
import { filter, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { DialogModule } from './components/dialog/dialog.module';
import { LoginModule } from './components/login/login.module';
import { PageWrapperModule } from './components/page-wrapper/page-wrapper.module';
import { RegisterModule } from './components/register/register.module';
import { SharedModule } from './components/shared.module';
import { AppMissingTranslationHandler } from './core/missing-translation-handler';
import {
  PwUpdateOption,
  PW_UPDATE_OPTION,
} from './core/services/app-update.service';
import { PipesModule } from './pipes/pipes.module';

registerLocaleData(localeKo);

const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const createMissingTranslationHandler = () => {
  return new AppMissingTranslationHandler();
};

const pwUpdateOption: PwUpdateOption = {
  messageTranslateKey: 'MSG.askUpdate',
  actionTranslateKey: 'BTN.update',
};

const matDialogDefaultOptions: MatDialogConfig = {
  maxWidth: 'auto',
  panelClass: 'dialog-page-panel',
  backdropClass: 'dialog-page-backdrop',
  hasBackdrop: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: createMissingTranslationHandler,
      },
    }),
    DialogModule,
    PipesModule,
    PageWrapperModule,
    SharedModule,
    LoginModule,
    RegisterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useFactory: (authService: AuthService) => {
        return new AuthInterceptor(
          authService,
          `${environment.apiServerUrl}/api`
        );
      },
      deps: [AuthService],
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'JPY' },
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    { provide: PW_STORAGE_PREFIX, useValue: 'kumahanin' },
    { provide: PW_UPDATE_OPTION, useValue: pwUpdateOption },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: matDialogDefaultOptions },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    let previousUrl: string;

    router.events
      .pipe(
        filter((event: Event): event is Scroll => event instanceof Scroll),
        tap((event) => {
          const [currentUrl] = router.url.split('?');

          if (event.position) {
            // 후방향 라우팅(뒤로가기)
            viewportScroller.scrollToPosition(event.position);
          } else if (event.anchor) {
            // 앵커
            viewportScroller.scrollToAnchor(event.anchor);
          } else if (currentUrl !== previousUrl) {
            // 전방향 라우팅
            viewportScroller.scrollToPosition([0, 0]);
          }

          previousUrl = currentUrl;
        })
      )
      .subscribe();
  }
}
