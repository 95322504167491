<app-kumahanin-header ngClass.lt-sm="small"></app-kumahanin-header>
<mat-sidenav-container #sideNav>
  <mat-sidenav position="end" [autoFocus]="false">
    <div class="nav-wrapper">
      <div class="logo" fxLayout fxLayoutAlign="center center">
        <img src="/assets/imgs/okta-logo.png" />
        <mat-icon
          class="close"
          mat-ripple
          fontSet="material-symbols-outlined"
          (click)="closeAll()"
          >close</mat-icon
        >
      </div>
      <mat-accordion #accordion>
        <ng-container *ngFor="let item of menu.MENU_LIST">
          <mat-expansion-panel *ngIf="!item.hide">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ item.name | translate }}</mat-panel-title>
            </mat-expansion-panel-header>
            <menu fxLayout="column" fxLayoutGap="12px">
              <li *ngFor="let subMenu of item.subMenu">
                <a [routerLink]="subMenu.path" (click)="sideNav.close()">{{
                  subMenu.name | translate
                }}</a>
              </li>
            </menu>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>

      <div class="user-menu-container">
        <ng-container *ngIf="!loginInfo">
          <a class="user-menu" (click)="onLoginClick()">{{
            'login' | translate
          }}</a>
          |
          <a class="user-menu" (click)="onRegisterClick()">{{
            'register' | translate
          }}</a>
        </ng-container>
        <ng-container *ngIf="loginInfo">
          <a class="user-menu">{{ loginInfo.userNm || loginInfo.email }}</a>
          |
          <a class="user-menu" (click)="onLogoutClick()">{{
            'logout' | translate
          }}</a>
        </ng-container>
        |
        <a class="user-menu" routerLink="/my">{{ 'MY' | translate }}</a>

        <button class="user-menu-button" (click)="onToggleLangClick()">
          {{ 'toggleLang' | translate }}
        </button>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <main wrapper>
      <router-outlet></router-outlet>
    </main>
    <app-kumahanin-footer></app-kumahanin-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
